* {
  margin: 0;
  padding: 0;
  bottom: 0; }

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html,
body {
  min-height: 100%;
  width: 100%;
  font-size: 14px;
  line-height: 1.6; }

nav,
footer,
header,
aside {
  display: block; }

a,
a:hover,
a:visited {
  color: inherit;
  text-decoration: none; }

*:focus,
*:active {
  outline: none; }

li {
  list-style: none; }

img {
  max-width: 100%;
  display: block; }

input,
button,
textarea,
select {
  font-family: inherit; }

input::-ms-clear {
  display: none; }

button {
  cursor: pointer; }
